import React, { useState, useEffect, Fragment } from 'react'
import { graphql, useStaticQuery, Link, PageProps, navigate } from 'gatsby'
import {
  CenteredResponsiveContainer,
  Heading,
  Layout,
  UnderlinedTextLink,
} from '@/components/Layout'
import { SEO } from '@/components/SEO'
import { Dialog, Disclosure, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { ChevronDownIcon, PlusSmIcon } from '@heroicons/react/solid'

import { ReactComponent as ArrowRight } from '@/images/arrow-right.svg'

type CareersQuery = {
  allGreenhouseOffice: {
    nodes: GreenhouseOfficeProps[]
  }
  allGreenhouseJob: {
    nodes: GreenhouseJobProps[]
  }
}
const CareersQuery = graphql`
  query {
    allGreenhouseOffice {
      nodes {
        id
        gh_Id
        name
        location
        jobs {
          ...GreenhouseJobFragment
        }
      }
    }
    allGreenhouseJob {
      nodes {
        id
        gh_Id
        title
        content
        location {
          name
        }
        departments {
          name
        }
      }
    }
  }
`

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const usEmail = 'uscareerinquiries@jumptrading.com'
const intlEmail = 'internationalcareerinquiries@jumptrading.com'
const campusEmail = 'campusrecruiting@jumptrading.com'
const studentJobKeywords = ['campus', 'intern']

type LocationFilters = { [location: GreenhouseOfficeProps['name']]: boolean }
type SearchTerms = string[]

const Careers = (props: PageProps) => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
  const data = useStaticQuery<CareersQuery>(CareersQuery)
  // temp: list of jobs to hide from the search/list UI:
  const jobsToHide = ['4301969', '4748198', '5365739']
  const allJobs = data.allGreenhouseJob.nodes.filter((a) => !jobsToHide.includes(a.gh_Id))
  // console.log('allJobs: ', allJobs)
  const [filteredJobs, setFilteredJobs] = useState<GreenhouseJobProps[]>(allJobs)
  const [selectedLocations, setSelectedLocations] = useState<LocationFilters>({})
  const [titleSearchPhrase, setTitleSearchPhrase] = useState<SearchTerms>([])
  const [searchPhrase, setSearchPhrase] = useState<SearchTerms>([])
  const [searchValue, setSearchValue] = useState<string>('')

  // handle greenhouse location name inconsistencies
  const locationsShim = {
    Australia: 'Sydney',
    Clifton: 'New Jersey',
    Israel: 'Tel Aviv',
    'New York City': 'New York',
    Slough: 'UK/EU',
  }

  let { nodes: offices } = data.allGreenhouseOffice
  // filter the offices down to locations with active, non-hidden jobs.
  offices = offices.filter((office) =>  {
    // offices must have jobs that pass the criteria:
    return office.jobs && office.jobs.filter((j) => {
      // must have at least 1 non-hidden job with location.name matching the office.name
      const officeName = office.name.trim()
      // shim the officeName to be the form that appears in "job.location".
      // ie. officeName: `New York City ` --> `New York`, so it can match on a job
      // location like: `Chicago, New York`, or `New York`.
      const normalizedName = locationsShim[officeName] || officeName
      return !jobsToHide.includes(j.gh_Id) && j.location.name.includes(normalizedName) 
    }).length >= 1
  })



  // to hardcode a location filter add an object like: { name: 'Remote' }
  const locationsToAdd = []

  // These special cases exist because the jobs under the "India" office in GH have their
  // location.name set to the city they are in (ie Gurgaon), rather than the "office" they belong to. ie:
  // {
  //     "id": "Greenhouse__Office__63485",
  //     "gh_Id": 63485,
  //     "name": "India",
  //     "location": "India ",
  //     "jobs": [
  //         {
  //             "id": "Greenhouse__Job__5052414",
  //             "gh_Id": "5052414",
  //             "title": "Trader",
  //             "content": "...",
  //             "offices": [
  //                 {
  //                     "id": "Greenhouse__Office__63485",
  //                     "name": "India",
  //                     "location": "India "
  //                 }
  //             ],
  //             "location": {
  //                 "name": "Gandhinagar"
  //             },
  //             "departments": [
  //                 {
  //                     "name": "Front Office "
  //                 }
  //             ]
  //         }
  //     ]
  // },
  
  // add Gurgaon as a location filter, if there are jobs open there
  const Gurgaon = 'Gurgaon'
  if (allJobs.some((j) => j.location.name.includes(Gurgaon))) {
    locationsToAdd.push({ name: Gurgaon })
  }

  const officeFilters = [...offices, ...locationsToAdd]
    // rm "Slough"
    .filter((o) =>  o.name !== 'Slough')
    .map((o) => {
      const name = o.name.trim()
      const val = locationsShim[name] || name

      return { value: val.trim(), label: val.trim() }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  const filters = [
    {
      id: 'location',
      name: 'Locations',
      options: officeFilters,
    },
  ]
  const filterJobs = (
    locations: LocationFilters,
    searchTerms: SearchTerms,
    titleSearchTerms: SearchTerms,
  ) => {
    let jobs = allJobs
    if (searchTerms && searchTerms.length >= 1) {
      jobs = jobs.filter((job) =>
        // job must contain all the search tems to pass
        searchTerms.every(
          (searchTerm) =>
            job.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            job.content.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      )
    }
    if (titleSearchTerms && titleSearchTerms.length >= 1) {
      jobs = jobs.filter((job) =>
        titleSearchTerms.some((searchTerm) =>
          job.title.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      )
    } else {
      // filter out student jobs by default
      jobs = jobs.filter(
        (job) =>
          !studentJobKeywords.some((searchTerm) =>
            job.title.toLowerCase().includes(searchTerm.toLowerCase()),
          ),
      )
    }
    const locationNames = Object.entries(locations)
      .filter(([k, v]) => v)
      .map(([k, v]) => k)
    if (locationNames && locationNames.length >= 1) {
      jobs = jobs.filter((job) =>
        locationNames.some((searchTerm) =>
          job.location.name.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      )
    }

    setFilteredJobs(jobs)
  }

  useEffect(() => {
    let locationFilters = {}
    let searchFilters = []
    let searchValue = ''
    let titleFilters = []

    if (props.location.search) {
      // take searchparams from the URL and set the values in the form
      const searchParams = new URLSearchParams(props.location.search)

      const locations = searchParams.get('locations') || ''
      const search = searchParams.get('search') || '' // searchPhrase
      const titles = searchParams.get('titleSearch') || '' // titleSearchPhrase

      // if the search params are different form values, update state
      if (locations.split(' ') !== Object.keys(selectedLocations)) {
        locationFilters = locations
          .split(' ')
          .filter((s) => s)
          .reduce((a, k) => {
            // hyphens replace spaces in location names (ie. New York)
            const key = k.replace('-', ' ')
            return { ...a, [key]: true }
          }, {})
      }
      if (search.split(' ') !== searchPhrase) {
        searchFilters = search.split(' ').filter((s) => s)
        searchValue = search
      }
      if (titles.split(' ') !== titleSearchPhrase) {
        titleFilters = titles.split(' ').filter((s) => s)
      }
    }
    setSelectedLocations(locationFilters)
    setSearchPhrase(searchFilters)
    setSearchValue(searchValue)
    setTitleSearchPhrase(titleFilters)
    filterJobs(locationFilters, searchFilters, titleFilters)
  }, [props.location.search])

  const createQueryString = (
    locations: LocationFilters,
    searchTerms: SearchTerms,
    titleSearchTerms: SearchTerms,
  ): string => {
    const params = new URLSearchParams('')

    const locationKeys = Object.keys(locations)
    if (locationKeys.length >= 1) {
      // locations are url encoded as a string with spaces, rather than a CSV
      // because the "+" symbol looks better than a url encoded "," (%2C).
      // "New York" becomes "New-York".
      params.set('locations', locationKeys.map((k) => k.replace(' ', '-')).join(' '))
    }
    if (searchTerms.length >= 1) {
      params.set('search', searchTerms.join(' '))
    }
    if (titleSearchTerms.length >= 1) {
      params.set('titleSearch', titleSearchTerms.join(' '))
    }
    return params.toString()
  }

  // push new query params, which will trickle down through props, into state
  const updateState = (queryParam: string) => {
    const newUrl = queryParam ? `/careers/?${queryParam}` : `/careers/`
    navigate(newUrl)
  }

  const handleSearch = (e) => {
    if (e.target.value !== searchValue) {
      setSearchValue(e.target.value || '')
    }
    const parts = e.target.value.split(' ').filter((v) => v)
    updateState(createQueryString(selectedLocations, parts, titleSearchPhrase))
  }
  const clearSearch = () => {
    updateState(createQueryString(selectedLocations, [], titleSearchPhrase))
  }
  const isFilterActive = (filterId: string, key: string): boolean => {
    if (filterId === 'location' && selectedLocations[key]) {
      return true
    } else if (filterId === 'student-jobs' && titleSearchPhrase.length >= 1) {
      return true
    }
    return false
  }
  const getFilterStyle = (filterId: string, key: string): string | null => {
    const activeStyles = 'text-jump-red border-solid border-b-jump-red'
    if (isFilterActive(filterId, key)) {
      return activeStyles
    }
    return null
  }

  const handleToggle = (filterSectionId: string) => (e) => {
    const { checked, value } = e.target
    if (filterSectionId === 'location') {
      let locations: LocationFilters
      if (value in selectedLocations) {
        const { [value]: thisLocation, ...others } = selectedLocations
        locations = others
      } else {
        locations = { ...selectedLocations, [value]: checked }
      }
      updateState(createQueryString(locations, searchPhrase, titleSearchPhrase))
    }
  }

  const studentJobsToggle = () => {
    let newSearchPhrase = []
    if (titleSearchPhrase.length >= 1) {
    } else {
      newSearchPhrase = studentJobKeywords
    }
    updateState(createQueryString(selectedLocations, searchPhrase, newSearchPhrase))
  }

  const studentJobsUI = (
    <div className="flex flex-col h-fit">
      <input
        id={`student-jobs-toggle`}
        name={`student-jobs`}
        defaultValue={titleSearchPhrase.length}
        type="checkbox"
        className="h-4 w-4 border-gray-300 rounded hidden"
        onChange={studentJobsToggle}
      />
      <label htmlFor={`student-jobs-toggle`}>
        <UnderlinedTextLink className={`${getFilterStyle('student-jobs', '')} mb-0`}>
          <span className="mb-0">
            Roles&nbsp;for&nbsp;Students&nbsp;
            {isFilterActive('student-jobs', '') ? '(x)' : null}
          </span>
        </UnderlinedTextLink>
      </label>
    </div>
  )
  return (
    <Layout {...{ className: 'overflow-hidden' }}>
      <SEO
        title="Careers"
        description="Jump is growing aggressively."
        pathname={props.location.pathname}
      />

      <section className="min-h-96 mt-16  mb-6">
        <div className="sm:max-w-7xl px-0 sm:px-2 md:px-4 sm:py-4 sm:mx-auto md:space-x-10 lg:px-8">
          <div className="border-l-8 border-l-jump-red border border-y-0 border-r-0 mb-10 pt-3">
            <div className="text-4xl md:text-5xl xl:text-6xl font-bold pl-7">
              <h1>
                Jump is growing.
                <br />
                Aggressively.
              </h1>
            </div>
          </div>
        </div>
        <CenteredResponsiveContainer>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10 lg:gap-x-20 gap-y-10">
            <div className="text-dark-gray text-lg">
              <div className="mb-5">
                Jump has more opportunity than people. Put simply, we have a lot to do and we need
                talented people to make it happen.
              </div>
              <div>Jump Trading is an Equal Opportunity Employer</div>
            </div>

            <div className="flex flex-col align-top gap-6 mb-10">
              <div>
                <Heading className="uppercase">United States</Heading>
                <UnderlinedTextLink>
                  <a
                    href={`mailto:${usEmail}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm sm:text-base md:text-md lg:text-lg"
                  >
                    {usEmail}
                  </a>
                </UnderlinedTextLink>
              </div>
              <div>
                <Heading className="uppercase">International</Heading>
                <UnderlinedTextLink>
                  <a
                    href={`mailto:${intlEmail}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm sm:text-base md:text-md lg:text-lg"
                  >
                    {intlEmail}
                  </a>
                </UnderlinedTextLink>
              </div>
              <div>
                <Heading className="uppercase">University</Heading>
                <UnderlinedTextLink>
                  <a
                    href={`mailto:${campusEmail}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm sm:text-base md:text-md lg:text-lg"
                  >
                    {campusEmail}
                  </a>
                </UnderlinedTextLink>
              </div>
            </div>
          </div>
        </CenteredResponsiveContainer>
      </section>

      {/* Mobile filter UI */}
      <Transition.Root show={mobileFiltersOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={setMobileFiltersOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-6 flex flex-col overflow-y-auto">
              <div className="px-4 flex items-center justify-between">
                <div className="text-lg font-medium text-gray-900">Filters</div>
                <button
                  type="button"
                  className="-mr-2 w-10 h-10 p-2 flex items-center justify-center text-gray-400 hover:text-gray-500"
                  onClick={() => setMobileFiltersOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Student jobs */}
              <Disclosure
                as="div"
                key={'student-jobs'}
                className="border-t border-gray-200 pt-4 pb-4"
                defaultOpen
              >
                {({ open }) => (
                  <fieldset key="student-fieldset">
                    <legend className="w-full px-2">
                      <Disclosure.Button className="w-full p-2 flex items-center justify-between text-gray-400 hover:text-gray-500">
                        <span className="text-sm font-medium text-gray-900">
                          Student&nbsp;Opportunities
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-5 w-5 transform',
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </legend>
                    <Disclosure.Panel className="pt-4 pb-2 px-4">{studentJobsUI}</Disclosure.Panel>
                  </fieldset>
                )}
              </Disclosure>

              {/* Search input */}
              <Disclosure
                as="div"
                key={'search'}
                className="border-t border-gray-200 pt-4 pb-4"
                defaultOpen
              >
                {({ open }) => (
                  <fieldset key="search-fieldset">
                    <legend className="w-full px-2">
                      <Disclosure.Button className="w-full p-2 flex items-center justify-between text-gray-400 hover:text-gray-500">
                        <span className="text-sm font-medium text-gray-900">Search&nbsp;Roles</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-5 w-5 transform',
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </legend>
                    <Disclosure.Panel className="pt-4 pb-2 px-4">
                      <div className="space-y-6">
                        <div className="flex items-center">
                          <input
                            type="text"
                            name="search"
                            id="search"
                            value={searchValue}
                            className=" focus:border-medium-gray-2 border-medium-gray-1 border-2 block w-full h-full sm:text-sm  rounded-sm pl-4"
                            placeholder="Search Roles"
                            onChange={handleSearch}
                            onKeyDown={(e) => e.key === 'Enter' && setMobileFiltersOpen(false)}
                          />
                          {searchValue && (
                            <button
                              className="flex px-2 rounded-full round bg-light-gray h-6 text-sm items-center text-dark-gray border-dark-gray ml-2"
                              onClick={clearSearch}
                            >
                              clear
                            </button>
                          )}
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </fieldset>
                )}
              </Disclosure>

              {/* Filters */}
              <form>
                {filters.map((section) => (
                  <Disclosure
                    as="div"
                    key={section.name + '-mobile'}
                    className="border-t border-gray-200 pt-4 pb-4"
                    {...(section.id === 'location' ? { defaultOpen: true } : null)}
                  >
                    {({ open }) => (
                      <fieldset key={section.name + '-fieldset'}>
                        <legend className="w-full px-2">
                          <Disclosure.Button className="w-full p-2 flex items-center justify-between text-gray-400 hover:text-gray-500">
                            <span className="text-sm font-medium text-gray-900">
                              {section.name}
                            </span>
                            <span className="ml-6 h-7 flex items-center">
                              <ChevronDownIcon
                                className={classNames(
                                  open ? '-rotate-180' : 'rotate-0',
                                  'h-5 w-5 transform',
                                )}
                                aria-hidden="true"
                              />
                            </span>
                          </Disclosure.Button>
                        </legend>
                        <Disclosure.Panel className="pt-4 pb-2 px-4">
                          {section.options
                            .sort((a, b) => a.label.localeCompare(b.label))
                            .map((option, optionIdx) => (
                              <div key={option.value + '-mobile'} className="flex flex-col h-fit">
                                <input
                                  id={`${section.id}-${optionIdx}-mobile`}
                                  name={`${section.id}[]`}
                                  defaultValue={option.value}
                                  type="checkbox"
                                  className="h-4 w-4 border-gray-300 rounded hidden"
                                  onChange={handleToggle(section.id)}
                                />
                                <label htmlFor={`${section.id}-${optionIdx}-mobile`}>
                                  <UnderlinedTextLink
                                    className={getFilterStyle(section.id, option.value)}
                                  >
                                    <>
                                      {option.label}{' '}
                                      {isFilterActive(section.id, option.value) ? '(x)' : null}
                                    </>
                                  </UnderlinedTextLink>
                                </label>
                              </div>
                            ))}
                        </Disclosure.Panel>
                      </fieldset>
                    )}
                  </Disclosure>
                ))}
              </form>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      {/* Filter controls above the jobs list */}
      <div id="careers" className="min-h-[88px] h-fit">
        <div className="w-[99vw] border-t-light-gray border-t-2 md:border-y-2 md:border-y-light-gray  ">
          <div className="md:max-w-7xl px-2 md:px-4 md:mx-auto md:space-x-10 lg:px-8  ">
            <div className="  flex justify-between items-center gap-x-3 min-h-[84px] h-fit py-1">
              <div className=" items-center gap-4 h-12 hidden md:flex min-w-[300px]">
                <label htmlFor="search" className="sr-only">
                  Search Careers
                </label>
                <input
                  type="text"
                  name="search"
                  id="search"
                  value={searchValue}
                  className=" focus:border-medium-gray-2 border-medium-gray-1 border-2 block w-full h-full sm:text-sm  rounded-sm pl-4"
                  placeholder="Search Roles"
                  onChange={handleSearch}
                />
                {searchValue && (
                  <button
                    className="flex px-3 rounded-full round bg-light-gray h-9 items-center text-dark-gray border-dark-gray"
                    onClick={clearSearch}
                  >
                    <span>clear</span>
                  </button>
                )}
              </div>
              <div className="hidden md:flex -mb-1 6xl:mr-16">{studentJobsUI}</div>
              <div className="block md:hidden items-center">
                <button
                  type="button"
                  className="inline-flex items-center lg:hidden"
                  onClick={() => setMobileFiltersOpen(true)}
                >
                  <span className="text-sm font-medium text-gray-700">Filters</span>
                  <PlusSmIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                </button>
              </div>

              <div className="md:hidden flex justify-evenly  items-center  flex-wrap gap-1 ">
                {(selectedLocations && Object.keys(selectedLocations).length >= 1) ||
                titleSearchPhrase.length ||
                (searchPhrase && searchPhrase.length >= 1) ? (
                  <>
                    {Object.keys(selectedLocations).map((key) => (
                      <>
                        <button
                          key={key}
                          className="flex px-2 rounded-lg round bg-light-gray text-xs h-5 items-center text-dark-gray border-dark-gray "
                          onClick={() =>
                            handleToggle('location')({ target: { value: key, checked: false } })
                          }
                        >
                          {key}&nbsp;(x)
                        </button>
                      </>
                    ))}
                    {titleSearchPhrase.length && (
                      <button
                        className="flex px-2 rounded-lg round bg-light-gray text-xs h-5 items-center text-dark-gray border-dark-gray "
                        onClick={studentJobsToggle}
                      >
                        Roles&nbsp;for&nbsp;students&nbsp;(x)
                      </button>
                    )}
                    {searchPhrase.map((key) => (
                      <>
                        <button
                          key={key}
                          className="flex px-2 rounded-lg round bg-light-gray text-xs h-5 items-center text-dark-gray border-dark-gray "
                          onClick={() =>
                            handleSearch({
                              target: { value: searchPhrase.filter((t) => t !== key).join(' ') },
                            })
                          }
                        >
                          {key}&nbsp;(x)
                        </button>
                      </>
                    ))}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* office list for filtering jobs, for wider screens */}
      <div className="min-h-[900px]">
        <div
          className="grid grid-cols-1 grid-rows-1
          md:grid-cols-[0.31fr_0.69fr]
          lg:grid-cols-[0.33fr_0.68fr]
          2xl:grid-cols-[0.34fr_0.67fr]
          3xl:grid-cols-[0.36fr_0.64fr]
          4xl:grid-cols-[0.38fr_0.62fr]
          5xl:grid-cols-[0.40fr_0.60fr]"
        >
          <div className="hidden md:block  min-h-[900px] justify-self-end  bg-white w-full">
            <div className="justify-self-end flex flex-col items-end  max-w-full h-full">
              <form className="min-w-fit  lg:w-72 pt-12 ">
                {filters.slice(0, 1).map((section, sectionIdx) => (
                  <div key={section.name + '-wide'} className={sectionIdx === 0 ? null : 'pt-10'}>
                    <fieldset className="pt-6 md:mx-5 ">
                      <legend className="block text-sm font-semibold text-gray-900">
                        <Heading className="text-black font-bold ">{section.name}</Heading>
                      </legend>
                      <div id="styled-scrollbar" className="overflow-y-auto h-[780px] ">
                        {section.options
                          .sort((a, b) => a.label.localeCompare(b.label))
                          .map((option, optionIdx) => (
                            <div key={option.value + '-wide'} className="flex flex-col  h-fit ">
                              <input
                                id={`${section.id}-${optionIdx}`}
                                name={`${section.id}[]`}
                                defaultValue={option.value}
                                type="checkbox"
                                className="h-4 w-4 border-gray-300 rounded hidden"
                                onChange={handleToggle(section.id)}
                              />
                              <label htmlFor={`${section.id}-${optionIdx}`}>
                                <UnderlinedTextLink
                                  className={
                                    selectedLocations[option.value]
                                      ? 'text-jump-red border-solid border-b-jump-red '
                                      : null
                                  }
                                >
                                  <>
                                    {option.label} {selectedLocations[option.value] ? '(x)' : null}
                                  </>
                                </UnderlinedTextLink>
                              </label>
                            </div>
                          ))}
                      </div>
                    </fieldset>
                  </div>
                ))}
              </form>
            </div>
          </div>

          {/* Job list  */}
          <div className="  min-h-[900px] justify-items-start bg-light-gray">
            <div className=" bg-light-gray h-full">
              <div className="flex justify-between items-center max-w-4xl">
                <Heading className="pl-6 md:pl-12 lg:pl-24 py-12 text-black font-semibold ">
                  Roles
                </Heading>
                <div className="justify-self-end text-sm font-medium text-dark-gray pr-6 md:pr-12 lg:pr-24 pt-6">
                  {filteredJobs.length}&nbsp;found
                </div>
              </div>
              <section
                role="list"
                id="styled-scrollbar"
                className=" overflow-y-auto overflow-x-hidden max-w-4xl  h-[700px]"
              >
                {filteredJobs.map((job) => (
                  <Link
                    key={job.gh_Id}
                    role="listitem"
                    to={`/careers/${job.gh_Id}/`}
                    state={{ prevPath: props.location.pathname + props.location.search }}
                    className=" group"
                  >
                    <div className="py-6 lg:py-4 group-hover:bg-background flex items-center pl-6 md:pl-12 lg:pl-24">
                      <div className="flex-1 min-w-0">
                        <p className="text-xl  lg:text-2xl font-medium text-black group-hover:text-jump-red">
                          {job.title}
                        </p>
                        <p className="text-base lg:text-lg text-dark-gray group-hover:text-black">
                          {job.location.name}
                        </p>
                      </div>
                      <div className="pr-7 lg:pr-14 justify-end">
                        <ArrowRight />
                      </div>
                    </div>
                  </Link>
                ))}
              </section>
            </div>
          </div>
        </div>
        <div className="flex items-start justify-center w-full my-20">
          <span className="block">
            Not finding what you're looking for?&nbsp;
            <Link to={`/careers/3909107/`} className="inline">
              <UnderlinedTextLink className="mb-0 inline">
                <span className="mb-0">Let us know</span>
              </UnderlinedTextLink>
            </Link>
          </span>
        </div>
      </div>
    </Layout>
  )
}

export default Careers
